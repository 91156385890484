import { render } from "./MassTextCompose.vue?vue&type=template&id=66537047"
import script from "./MassTextCompose.vue?vue&type=script&lang=js"
export * from "./MassTextCompose.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "66537047"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('66537047', script)) {
    api.reload('66537047', script)
  }
  
  module.hot.accept("./MassTextCompose.vue?vue&type=template&id=66537047", () => {
    api.rerender('66537047', render)
  })

}

script.__file = "src/views/MassTextCompose.vue"

export default script