<template>
  <div class="py-4">
    <form @submit.prevent="compose">
      <div class="flex justify-between">
        <select
          class="form-select w-full mr-2 px-2 py-3"
          required
          v-model="text.twilio_number"
        >
          <option disabled selected value="default">
            Select Phone Numbers
          </option>
          <option
            v-for="phoneNumber in locationPhoneNumbers"
            :value="phoneNumber.twilio_number"
            :key="phoneNumber.id"
          >
            {{ phoneNumber.location_name }} ({{ phoneNumber.twilio_number }})
          </option>
        </select>
        <div class="w-full ml-2">
          <select
            class="form-select w-full px-2 py-3"
            v-if="contactGroups && contactGroups.length != 0"
            required
            @change="listenToGroup"
            v-model="text.contact_group"
          >
            <option disabled selected value="default">
              Select Contact Groups
            </option>
            <option value="all">
              Send to All
            </option>
            <option
              v-for="contactGroup in contactGroups"
              :value="contactGroup.id"
              :key="contactGroup.id"
            >
              {{ contactGroup.contact_group_label }}
            </option>
          </select>
          <div
            v-else
            class="w-full py-2 text-center border-gray-400 border-2 h-full items-center flex justify-center"
          >
            No contact groups,
            <router-link
              to="/mass-text/phone-lists"
              class="text-blue-600 underline text-center ml-1"
            >
              Add one
            </router-link>
          </div>
        </div>
      </div>

      <input
        v-model="text.title"
        class="w-full mt-5 py-3 pl-2 border-black"
        required
        placeholder="Title"
      />

      <textarea
        class="w-full mt-3 p-2"
        v-model="text.message"
        required
        placeholder="Message"
      >
      </textarea>

      <input
        class="mt-3 block"
        ref="image"
        type="file"
        @change="previewImage"
        accept="image/*"
      />

      <div v-if="imageData || img" class="mt-2 bg-gray-600 w-max relative">
        <button
          class="absolute -top-2 -right-3 bg-white rounded-full"
          @click="removeImage()"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            width="30px"
            data-icon="times-circle"
            class="svg-inline--fa fa-times-circle fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
            ></path>
          </svg>
        </button>
        <img height="268" width="356" :src="img" />
      </div>

      <div role="alert" class="my-3" v-if="errorMsg != ''">
        <div
          class="border text-center border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
        >
          <p>{{ errorMsg }}.</p>
        </div>
      </div>

      <button
        class="w-auto mt-3 block mx-auto bg-gray-700 text-white rounded-full py-2 px-3"
        type="submit"
        :disabled="isSubmitted"
        :class="isSubmitted ? 'opacity-75' : ''"
      >
        {{ isSubmitted ? "Sending..." : "Send" }}

        {{
          receipientsCount && receipientsCount
            ? `to ${receipientsCount} receipients`
            : ""
        }}
      </button>
    </form>
  </div>
  <LoadingComponent v-if="isSubmitted"> </LoadingComponent>
</template>

<script>
const firebase = require("../firebaseConfig");
import axios from "axios";
import LoadingComponent from "../components/LoadingComponent";
export default {
  name: "MassTextComposeComponent",
  components: { LoadingComponent },
  data: () => ({
    text: {
      twilio_number: "default",
      contact_group: "default",
      send_to_all: false,
      title: "",
      message: "",
      mediaMessage: "",
    },
    isSubmitted: false,
    user: {},
    contactGroups: [],
    imageName: "",
    imageData: null,
    mass_text: {},
    errorMsg: "",
    receipientsCount: 0,
    imageDownloadableURL: null,
    img: null,
    uploadValue: 0,
    locationPhoneNumbers: [],
  }),
  watch: {
    $route() {
      if (!this.$route.params.masstext_id) {
        this.text = {
          twilio_number: "default",

          contact_group: "default",
          send_to_all: false,
          title: "",
          message: "",
          mediaMessage: "",
        };
      }
    },
  },
  async mounted() {
    this.user = await this.getUserInfo();
    this.checkPageAccess();
    this.prefillCompose();
    this.getBusinessInfo();
    this.getContactGroups();
    this.getLocationPhoneNumbers();
    this.listenToGroup();
  },
  methods: {
    listenToGroup(evt) {
      let phoneNumbers = [];
      let contactGroups = [];
      let selectedContactGroup = [];

      if (evt && evt.target)
        if (evt.target.value === "all") {
          this.contactGroups.map((contactGroup) => {
            contactGroups.push(contactGroup.id);
            contactGroup.contact_number.map((phoneNumber) => {
              phoneNumbers.push(phoneNumber);
            });
          });

          selectedContactGroup = Array.from(new Set([...phoneNumbers]));
          this.receipientsCount = selectedContactGroup.length;
        } else {
          selectedContactGroup = this.contactGroups.filter(
            (contact) => contact.id == evt.target.value
          );

          selectedContactGroup = selectedContactGroup[0];
          contactGroups.push(this.text.contact_group);
          this.receipientsCount = selectedContactGroup.contact_number.length;
        }

      console.info("slected contact group", this.text);
    },
    prefillCompose() {
      const massTextId = this.$route.params.masstext_id;
      this.getContactGroups();
      if (massTextId) {
        this.getSingleMassText(massTextId);
      }
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async checkPageAccess() {
      const business = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get();
      if (!business.data().mass_text_active) {
        this.$router.push("/dashboard");
      }
    },
    async getPhoneNumber(locationId) {
      const business = await this.getBusinessInfo();

      const businessId =
        this.user.role === "superadmin"
          ? this.$route.params.business_id
          : business.business_id;

      return new Promise((resolve, reject) => {
        firebase.db
          .doc(`businesses/${businessId}/locations/${locationId}`)
          .get()
          .then((doc) => {
            resolve({ id: doc.id, ...doc.data() });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getSingleMassText(massTextId) {
      const business = await this.getBusinessInfo();

      const businessId =
        this.user.role === "superadmin"
          ? this.$route.params.business_id
          : business.business_id;

      await firebase.db
        .collection("businesses")
        .doc(businessId)
        .collection("mass_texts")
        .doc(massTextId)
        .onSnapshot(async (doc) => {
          this.mass_text = {
            id: doc.id,
            ...doc.data(),
          };

          const location = await this.getPhoneNumber(
            this.mass_text.location_id
          );

          this.receipientsCount = this.mass_text.mass_text_recipients_count;

          const mass_text =
            this.mass_text.mass_text_group === "all"
              ? this.mass_text.mass_text_group
              : this.mass_text.mass_text_group_id;
          this.text.twilio_number = location.twilio_number;
          this.text.contact_group = mass_text;
          this.text.title = this.mass_text.mass_text_title;
          this.text.message = this.mass_text.mass_text_message;

          if (this.mass_text.mass_text_attachment)
            this.loadImage(businessId, this.mass_text.mass_text_attachment);
        });
    },
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    async loadImage(id, bucketPath) {
      const url = await firebase.storage
        .ref(id)
        .child("mass-text")
        .child(bucketPath)
        .getDownloadURL();
      this.img = url;
      this.imageName = bucketPath;
    },
    async getContactGroups() {
      const business = await this.getBusinessInfo();

      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.contactGroups = [];
          docs.forEach((doc) => {
            this.contactGroups.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    removeImage() {
      this.$refs.image.value = null;
      this.imageData = null;
      this.img = null;
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.imageData = event.target.files[0];
      this.img = URL.createObjectURL(this.imageData);
      this.imageName = `${this.imageData.lastModified}${this.imageData.name}`;
    },
    async getLocationPhoneNumbers() {
      const business = await this.getBusinessInfo();

      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("locations")
        .where("status", "==", "active")
        .onSnapshot((docs) => {
          this.locationPhoneNumbers = [];
          docs.forEach((doc) => {
            this.locationPhoneNumbers.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    async compose() {
      if (
        this.text.contact_group === "default" ||
        this.text.twilio_number === "default"
      ) {
        this.errorMsg = "Please fill out both dropdowns";
        return false;
      }
      this.errorMsg = "";
      const business = await this.getBusinessInfo();
      this.isSubmitted = true;
      let selectedContactGroup = [];
      let is_conversation = false;

      let phoneNumbers = [];
      let contactGroups = [];
      if (this.text.contact_group === "all") {
        this.contactGroups.map((contactGroup) => {
          contactGroups.push(contactGroup.id);
          contactGroup.contact_number.map((phoneNumber) => {
            phoneNumbers.push(phoneNumber);
          });
        });

        selectedContactGroup.contact_number = Array.from(
          new Set([...phoneNumbers])
        );
      } else {
        selectedContactGroup = this.contactGroups.filter(
          (contact) => contact.id == this.text.contact_group
        );

        selectedContactGroup = selectedContactGroup[0];
        contactGroups.push(this.text.contact_group);
      }

      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("mass_texts")
        .add({
          location_id: this.user.location_id,
          ...(this.img && {
            mass_text_attachment: `${this.imageName}`,
          }),
          mass_text_title: this.text.title,
          mass_text_message: this.text.message,
          mass_text_recipients_count:
            selectedContactGroup.contact_number.length,
          mass_text_timestamp: new Date(),
          // mass_text_recipients_numbers: selectedContactGroup.contact_number,
          mass_text_group_id: this.text.contact_group,
        });

      const location = this.locationPhoneNumbers.filter(
        (phonenumber) => phonenumber.twilio_number === this.text.twilio_number
      );

      // increment mass_text_campaigns and messages_sent in locations collections
      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("locations")
        .doc(location[0].id)
        .update({
          location_mass_text_campaigns: firebase.firestore.FieldValue.increment(
            1
          ),
          location_messages_sent: firebase.firestore.FieldValue.increment(
            selectedContactGroup.contact_number.length
          ),
        });

      // increment mass_text_campaigns and messages_sent in business
      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .update({
          mass_text_campaigns: firebase.firestore.FieldValue.increment(1),
          messages_sent: firebase.firestore.FieldValue.increment(
            selectedContactGroup.contact_number.length
          ),
        });

      if (this.imageData) {
        const storageRef = firebase.storage.ref(
          `${business.business_id}/mass-text/${this.imageName}`
        );
        await storageRef.put(this.imageData);

        this.imageDownloadableURL = await firebase.storage
          .ref(business.business_id)
          .child("mass-text")
          .child(this.imageName)
          .getDownloadURL();
      }

      const messages = selectedContactGroup.contact_number.map(
        (contactGroup) => {
          return {
            to: contactGroup,
            from: this.text.twilio_number,
            message: this.text.message,
            media: this.imageDownloadableURL || this.img,
          };
        }
      );

      messages.forEach(async (message) => {
        let conversation = {};
        let last_message = "";

        is_conversation = false;

        // check if this is a existing conversation
        await firebase.db
          .collection("businesses")
          .doc(business.business_id)
          .collection("locations")
          .doc(location[0].id)
          .collection("conversations")
          .where("customer_number", "==", message.to)
          .get()
          .then(async (snapshot) => {
            if (!snapshot.empty) {
              conversation = {
                id: snapshot.docs[0].id,
                ...snapshot.docs[0].data(),
              };
              // set flag for message collection
              is_conversation = true;

              if (message.message.length > 0) {
                last_message = message.message;
              }
              if (message.message.length > 60) {
                last_message = message.message.substring(0, 60) + "...";
              }
              // update conversation collection
              await firebase.db
                .collection("businesses")
                .doc(business.business_id)
                .collection("locations")
                .doc(location[0].id)
                .collection("conversations")
                .doc(conversation.id)
                .update({
                  last_message_timestamp: new Date(),
                  last_message: last_message,
                });
            }
          });
        const authToken = await firebase.auth.currentUser.getIdToken();

        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/sendTwilio`,
          headers: {
            Authorization: authToken,
          },
          data: {
            to: message.to,
            from: message.from,
            content: message.message,
            display_name: this.user.display_name,
            ...(message.media && {
              image_attachment: message.media,
            }),
            is_conversation: is_conversation,
            business_id: business.business_id,
            location_id: location[0].id,
            is_mass_text: true,
            is_review_text: false,
            is_send_text: false,
          },
        });
      });

      this.isSubmitted = false;
      this.text = {
        twilio_number: "default",
        contact_group: "default",
        isSubmitted: false,
        send_to_all: false,
        title: "",
        message: "",
      };
      this.getLocationPhoneNumbers();
      this.imageDownloadableURL = this.removeImage();
    },
  },
};
</script>
