import { render } from "./MassTextComposeComponent.vue?vue&type=template&id=559f521c"
import script from "./MassTextComposeComponent.vue?vue&type=script&lang=js"
export * from "./MassTextComposeComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "559f521c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('559f521c', script)) {
    api.reload('559f521c', script)
  }
  
  module.hot.accept("./MassTextComposeComponent.vue?vue&type=template&id=559f521c", () => {
    api.rerender('559f521c', render)
  })

}

script.__file = "src/components/MassTextComposeComponent.vue"

export default script