<template>
  <div
    class="h-screen bg-gray-300 fixed w-full left-0 top-0 opacity-75 z-10 flex"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class=""
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: none; display: block; shape-rendering: auto;"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
        fill="#1d1d1d"
        stroke="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 51;360 50 51"
        ></animateTransform>
      </path>
      <!-- [ldio] generated by https://loading.io/ -->
    </svg>
  </div>
</template>
<script>
export default {
  name: "LoadingComponent",
};
</script>
