<template>
  <MassTextComposeComponent />
</template>

<script>
import MassTextComposeComponent from '../components/MassTextComposeComponent.vue'

export default {
  name: 'MassTextCompose',
  components: {
    MassTextComposeComponent,
  },
}
</script>
