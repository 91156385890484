import { render } from "./LoadingComponent.vue?vue&type=template&id=5c2a8ed1"
import script from "./LoadingComponent.vue?vue&type=script&lang=js"
export * from "./LoadingComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c2a8ed1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c2a8ed1', script)) {
    api.reload('5c2a8ed1', script)
  }
  
  module.hot.accept("./LoadingComponent.vue?vue&type=template&id=5c2a8ed1", () => {
    api.rerender('5c2a8ed1', render)
  })

}

script.__file = "src/components/LoadingComponent.vue"

export default script